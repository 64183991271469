import React from 'react';
import { ModelZoneInfo } from '../../ZoneInfo/ZoneInfo.model';

export default function townOptions(zoneName: string, zoneInfos: ModelZoneInfo[]) {
  let currentTowns: string[] = [];
  zoneInfos.forEach((zoneRow) => {
    if (zoneRow.zoneName === zoneName) {
      currentTowns = zoneRow.towns.split('\n').map((town) => town.trim());
    }
  });
  return (
    <>
      <option value="">-</option>
      {currentTowns.map((town) => (
        <option value={town} key={town}>{town}</option>
      ))}
    </>
  );
}
