import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RootState } from '../Domains/Infrastructure/Redux/store';
import QueueForm from '../Domains/Queue/Components/QueueForm';
import QueueInQueue from '../Domains/Queue/Components/QueueInQueue';
import { ModelQueue } from '../Domains/Queue/Queue.model';
import { queueGetAll } from '../Domains/Queue/Queue.services';
import { SITE_NAME } from '../Config/constant';

function Driver() {
  const [queues, setQueues] = useState<ModelQueue[]>([]);
  const { auth } = useSelector((state: RootState) => state);
  const {
    user: authUser,
  } = useAuth0();

  const callQueueGetAll = async () => {
    if (auth.accessToken !== false) {
      const resultQueues = await queueGetAll(auth.accessToken);
      if (resultQueues !== undefined) {
        setQueues(resultQueues);
      }
    }
  };
  useEffect(() => {
    callQueueGetAll();
  }, [auth.accessToken]);

  let inQueue = false;
  let activeQueue: ModelQueue | undefined;
  queues.forEach((queueRow) => {
    if (queueRow.sub === authUser?.sub) {
      activeQueue = queueRow;
      inQueue = true;
    }
  });

  return (
    <div className="driver">
      <Helmet>
        <title>{`Add to Queue - ${SITE_NAME}`}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={8}>
          <h2>Add to Queue</h2>
          {inQueue && activeQueue !== undefined ? (
            <QueueInQueue queue={activeQueue} refetch={callQueueGetAll} />
          ) : (
            <QueueForm refetch={callQueueGetAll} />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Driver;
