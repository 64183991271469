import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Col, Row,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profileIsEmpty from '../Infrastructure/Auth/profileIsEmpty';
import { RootState } from '../Infrastructure/Redux/store';
import { actionUpdateUser } from '../Infrastructure/Redux/userSlice';
import { usersUpdateProfile } from './User.services';
import { ModelUser, modelUserInit } from './Users.model';

function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: authUser,
    getAccessTokenSilently,
  } = useAuth0();
  const user = useSelector((state: RootState) => state.user);
  const [profile, setProfile] = useState<ModelUser>(modelUserInit);
  useEffect(() => {
    const newProfile: ModelUser = { ...user };
    if (authUser?.email !== undefined) {
      newProfile.email = authUser.email;
    }
    setProfile(newProfile);
  }, [user, authUser]);
  const handleChange = (field: string, value: string) => {
    const newProfile: ModelUser = { ...profile };
    newProfile[field] = value;
    setProfile(newProfile);
  };
  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently();
    const updatedUser = await usersUpdateProfile(accessToken, profile);
    if (updatedUser !== undefined) dispatch(actionUpdateUser(updatedUser));
    navigate('/dashboard');
  };
  return (
    <div className="user-profile">
      {profileIsEmpty(profile) ? (
        <Alert>Please enter name and phone before continuing</Alert>
      ) : null}
      <h1>Profile</h1>
      <Row>
        <Col md={6}>
          <label>First Name</label>
          <input
            type="text"
            value={profile.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
          />
        </Col>
        <Col md={6}>
          <label>Last Name</label>
          <input
            type="text"
            value={profile.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
          />
        </Col>
        <Col md={6}>
          <label>Phone</label>
          <input
            type="text"
            value={profile.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
          />
        </Col>
        <Col md={6}>
          <label>email</label>
          <input type="text" disabled value={profile.email} />
        </Col>
      </Row>
      <Button onClick={handleSave}>Save</Button>
    </div>
  );
}

export default UserProfile;
