import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../Infrastructure/Redux/store';
import { EnumSettingName } from './Settings.enum';
import { ModelSettings } from './Settings.model';
import { settingsGetAll, settingsUpdate } from './Settings.services';

function SettingsInput({
  settingName,
}: {
  settingName: EnumSettingName,
}) {
  const [settings, setSettings] = useState<ModelSettings[]>([]);
  const { auth } = useSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      if (auth.accessToken !== false) {
        const resultSettings = await settingsGetAll(auth.accessToken);
        if (resultSettings !== undefined) {
          setSettings(resultSettings);
        }
      }
    })();
  }, [auth.accessToken]);
  let value = '';
  settings.forEach((settingRow) => {
    if (settingRow.settingName === settingName) {
      value = settingRow.value;
    }
  });
  const handleChange = (inputValue: string) => {
    let missingSetting = true;
    const newSettings = settings.map((settingRow) => {
      const newSettingRow = { ...settingRow };
      if (settingRow.settingName === settingName) {
        newSettingRow.value = inputValue;
        missingSetting = false;
      }
      return newSettingRow;
    });
    if (missingSetting) {
      newSettings.push({
        settingName,
        value: inputValue,
      });
    }
    setSettings(newSettings);
  };
  const handelSave = async () => {
    if (auth.accessToken !== false) {
      settingsUpdate(auth.accessToken, { settingName, value });
    }
  };
  return (
    <div className="settings-input">
      <label>{settingName}</label>
      <textarea value={value} onChange={(e) => handleChange(e.target.value)} />
      <Button onClick={handelSave}>Save</Button>
    </div>
  );
}

export default SettingsInput;
