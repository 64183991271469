import { EnumRoles } from '../Domains/Users/Users.model';

export const SITE_NAME = 'NVLux';

export const ROLES: EnumRoles[] = [
  EnumRoles.Admin,
  EnumRoles.Affiliate,
  EnumRoles.Driver,
  EnumRoles.Dispatch,
];

export const LABEL_ZONE = 'Please select a zone';

export const QUEUE_FIELD_LABEL: {
  [key: string]: string,
} = {
  zoneName: 'Zone Name',
  town: 'Town',
  arrivalTime: 'Estimated Arrival',
  leaveTime: 'Departure',
  carYear: 'Vehicle Year',
  carType: 'Vehicle Model',
  carPlate: 'License Plate',
  note: 'Note',
};

export const AUTH_DOMAIN = 'dev-z13707r2.us.auth0.com';
export const AUTH_CLIENTID = 'CWxRt9wmAd9qHNWl9hRNpMfdavR5KHVZ';
export const AUTH_SLS_AUDIENCE = 'https://m2j5nq5v52.execute-api.us-east-1.amazonaws.com';

// export const SLS_ENDPOINT = 'http://localhost:4000';
