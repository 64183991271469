import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../../Config/constant';
import SettingsInput from '../../Domains/Settings/SettingInput';
import { EnumSettingName } from '../../Domains/Settings/Settings.enum';

function SettingsCar() {
  return (
    <div className="settings car">
      <Helmet>
        <title>{`Car Settings - ${SITE_NAME}`}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1>Car Settings</h1>
          <SettingsInput
            settingName={EnumSettingName.CarType}
          />
          <SettingsInput
            settingName={EnumSettingName.CarYear}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SettingsCar;
