import React from 'react';
import Logo from '../Layouts/Logo';

export default function Home() {
  return (
    <div className="home">
      <Logo />
    </div>
  );
}
