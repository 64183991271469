import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { AUTH_CLIENTID, AUTH_DOMAIN, AUTH_SLS_AUDIENCE } from '../../../../Config/constant';

function AuthProvider({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate(appState && appState.returnTo ? appState.returnTo : '/');
  };

  return (
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENTID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience={AUTH_SLS_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
}

export default AuthProvider;
