import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../../Config/constant';
import UsersTable from '../../Domains/Users/UsersTable';

function SettingsUsers() {
  const [onlyNewUsers, setOnlyNewUsers] = useState(false);
  return (
    <div className="settings car">
      <Helmet>
        <title>{`Users - ${SITE_NAME}`}</title>
      </Helmet>
      <div className="heading-with-action">
        <h1>Users</h1>
        <div>
          <input type="checkbox" checked={onlyNewUsers} onChange={() => setOnlyNewUsers(!onlyNewUsers)} />
          {' '}
          New Users
        </div>
      </div>
      <UsersTable filterForNoRoles={onlyNewUsers} />
    </div>
  );
}

export default SettingsUsers;
