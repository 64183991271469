import { EnumSettingName } from '../../Settings/Settings.enum';
import { ModelSettings } from '../../Settings/Settings.model';

export default function settingsOptions(settingName: EnumSettingName, settings: ModelSettings[]) {
  let options: string[] = [];
  settings.forEach((settingsRow) => {
    if (settingsRow.settingName === settingName) {
      options = settingsRow.value.split('\n');
    }
  });
  return options;
}
