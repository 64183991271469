import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Tab, Tabs,
} from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { EnumRoles } from '../Domains/Users/Users.model';
import QueueByZone from '../Domains/Queue/Components/QueueByZone';
import { ModelZoneInfo } from '../Domains/ZoneInfo/ZoneInfo.model';
import { zoneInfoGetAll } from '../Domains/ZoneInfo/ZoneInfo.services';
import { SITE_NAME } from '../Config/constant';

function Admin() {
  const [zoneInfos, setZoneInfos] = useState<ModelZoneInfo[]>([]);
  const {
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const resultZoneInfo = await zoneInfoGetAll(accessToken);
      if (resultZoneInfo !== undefined) {
        setZoneInfos(resultZoneInfo);
      }
    })();
  }, [getAccessTokenSilently]);
  return (
    <div className="admin">
      <Helmet>
        <title>{`Dispatch - ${SITE_NAME}`}</title>
      </Helmet>
      <Tabs
        className="mb-3"
      >
        {zoneInfos.map((q) => (
          <Tab key={q.zoneName} eventKey={q.zoneName} title={q.zoneName}>
            <QueueByZone zoneName={q.zoneName} role={EnumRoles.Admin} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default Admin;
