/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initAuthSlice: { accessToken: false | string } = {
  accessToken: false,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: initAuthSlice,
  reducers: {
    actionUpdateAccessToken: (state, action: PayloadAction<{ accessToken: string | false }>) => {
      state.accessToken = action.payload.accessToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  actionUpdateAccessToken,
} = authSlice.actions;
