/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModelUser, modelUserInit } from '../../Users/Users.model';

export const userSlice = createSlice({
  name: 'user',
  initialState: modelUserInit,
  reducers: {
    actionUpdateUser: (state, action: PayloadAction<ModelUser>) => {
      state.roles = action.payload.roles;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.carPlate = action.payload.carPlate;
      state.carType = action.payload.carType;
      state.carYear = action.payload.carYear;
    },
    actionUpdateUserField: (state, action: PayloadAction<{ field: string, value: string }>) => {
      state[action.payload.field] = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  actionUpdateUser,
  actionUpdateUserField,
} = userSlice.actions;
