import React from 'react';

function Logo() {
  return (
    <div className="logo">
      <div className="nv">NV</div>
      <div className="lux">LUX</div>
    </div>
  );
}

export default Logo;
