import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../../Config/constant';
import SettingsInput from '../../Domains/Settings/SettingInput';
import { EnumSettingName } from '../../Domains/Settings/Settings.enum';

function SettingsAnnouncement() {
  return (
    <div className="settings car">
      <Helmet>
        <title>{`Announcement Settings - ${SITE_NAME}`}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col md={6}>
          <h1>Announcement</h1>
          <SettingsInput
            settingName={EnumSettingName.Announcement}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SettingsAnnouncement;
