import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNavWrapper from './SideNavWrapper';

function WithSideNav() {
  return (
    <div className="dashboard">
      <SideNavWrapper>
        <Outlet />
      </SideNavWrapper>
    </div>
  );
}

export default WithSideNav;
