import {
  MDBIcon, MDBSideNav,
} from 'mdb-react-ui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import MainNav from './MainNav';

function SideNavWrapper({ children }: { children: JSX.Element }) {
  const navBreakpoint = 992;
  const [navOpen, setNavOpen] = useState(true);
  const [navStayOpen, setNavStayOpen] = useState(window.innerWidth > navBreakpoint);
  const sidenavContent = useRef(null);
  const [container, setContainer] = useState<any>();

  const handleResize = () => {
    // console.log(window.innerWidth);
    if (window.innerWidth < navBreakpoint) {
      setNavOpen(false);
      setNavStayOpen(false);
    } else {
      setNavOpen(true);
      setNavStayOpen(true);
    }
  };
  useEffect(() => {
    handleResize();
    setContainer(sidenavContent.current);
    window.addEventListener('resize', handleResize);
  }, []);

  const location = useLocation();
  useEffect(() => {
    handleResize();
  }, [location]);

  return (
    <div className="App">
      <MDBSideNav
        isOpen={navOpen}
        mode="side"
        constant={navStayOpen}
        contentRef={container}
        bgColor="dark"
        color="light"
        backdrop={!navStayOpen}
        onHide={() => setNavOpen(false)}
      >
        <Logo />
        <MainNav />
      </MDBSideNav>
      <div>
        {navStayOpen ? null : (
          <div className="mobile-menu">
            <Button onClick={() => setNavOpen(!navOpen)} className="nav-open-trigger">
              <MDBIcon fas icon="bars" />
            </Button>
            <Logo />
          </div>
        )}
        <div ref={sidenavContent}>
          <div className="main-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNavWrapper;
