import axios from 'axios';
import { errorHandler } from '../Infrastructure/errorHandler';
import { ModelZoneInfo } from './ZoneInfo.model';

export const zoneInfoGetAll = async (accessToken: string) => {
  const results = await axios.get<{ zoneInfo: ModelZoneInfo[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/zone-info`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.zoneInfo || [];
};

export const zoneInfoUpdate = async (accessToken: string, zoneInfo: ModelZoneInfo) => {
  const results = await axios.post<{ zoneInfo: ModelZoneInfo }>(
    `${process.env.REACT_APP_SLS_SERVER}/zone-info/update`,
    { ...zoneInfo },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data;
};

export const zoneInfoDelete = async (accessToken: string, id: string) => {
  const results = await axios.post<{ zoneInfo: { _id: string } }>(
    `${process.env.REACT_APP_SLS_SERVER}/zone-info/delete`,
    { id },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data;
};
