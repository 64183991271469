import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

import WithSideNav from './Layouts/WithSideNav';
import Admin from './Pages/Admin';
import Client from './Pages/Affiliate';
import Dashboard from './Pages/Dashboard';
import Driver from './Pages/Driver';
import Home from './Pages/Home';
import Profile from './Pages/Profile';
import SettingsAnnouncement from './Pages/Settings/SettingsAnnouncement';
import SettingsCar from './Pages/Settings/SettingsCars';
import SettingsQueue from './Pages/Settings/SettingsQueueInfo';
import SettingsUsers from './Pages/Settings/SettingsUsers';
import './scss/app.scss';
import { SITE_NAME } from './Config/constant';

function App() {
  ReactGA.initialize('G-51VMWN9GT1');
  const location = useLocation();
  useEffect(() => {
    ReactGA.send('pageview');
  }, [location]);
  return (
    <div className="App">
      <Helmet>
        <title>{SITE_NAME}</title>
      </Helmet>
      <Routes>
        <Route path="dashboard" element={<WithSideNav />}>
          <Route path="queue" element={<Driver />} />
          <Route path="zones" element={<Client />} />
          <Route path="dispatch" element={<Admin />} />
          <Route path="settings/car" element={<SettingsCar />} />
          <Route path="settings/zones" element={<SettingsQueue />} />
          <Route path="settings/users" element={<SettingsUsers />} />
          <Route path="settings/announcement" element={<SettingsAnnouncement />} />
          <Route path="profile" element={<Profile />} />
          <Route index element={<Dashboard />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
