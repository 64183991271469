import axios from 'axios';
import { errorHandler } from '../Infrastructure/errorHandler';
import { ModelSettings } from './Settings.model';

export const settingsGetAll = async (accessToken: string) => {
  const results = await axios.get<{ settings: ModelSettings[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/settings`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.settings || [];
};

export const settingsUpdate = async (accessToken: string, setting: ModelSettings) => {
  const results = await axios.post<{ settings: ModelSettings[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/settings/update`,
    { ...setting },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data;
};
