import { useAuth0 } from '@auth0/auth0-react';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { MDBDatatable } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { usersGetAll } from './User.services';
import { ModelUser } from './Users.model';
import UserRoles from './UsersRoles';

function UsersTable({ filterForNoRoles }: { filterForNoRoles: boolean }) {
  const [modalUser, setModalUser] = useState<ModelUser | undefined>(undefined);
  const [users, setUsers] = useState<ModelUser[]>([]);
  const {
    getAccessTokenSilently,
  } = useAuth0();
  const callUsersGetAll = async () => {
    const accessToken = await getAccessTokenSilently();
    const newUsers = await usersGetAll(accessToken);
    if (newUsers !== undefined) {
      setUsers(newUsers);
    }
  };
  useEffect(() => {
    callUsersGetAll();
  }, []);
  const handleRoleClick = (user: ModelUser) => {
    setModalUser(user);
  };
  const columns = [
    { label: 'Name', field: 'name' },
    { label: 'Roles', field: 'rolesDisplay' },
    { label: 'Phone', field: 'phone' },
    { label: 'Email', field: 'email' },
    { label: 'Car Info', field: 'carInfo' },
  ];
  const filteredUsers: ModelUser[] = [];
  users.forEach((userRow) => {
    if (filterForNoRoles) {
      if (userRow.roles.length === 0) filteredUsers.push(userRow);
    } else {
      filteredUsers.push(userRow);
    }
  });
  const advancedData = {
    columns,
    rows: filteredUsers.map((user) => ({
      ...user,
      phone: (
        <a href={parsePhoneNumber(user.phone, 'US').getNumber('rfc3966')}>
          {parsePhoneNumber(user.phone, 'US').getNumber('national')}
        </a>
      ),
      email: (
        <a href={`mailto:${user.email}`}>
          {user.email}
        </a>
      ),
      carInfo: user.carPlate !== ''
        ? `${user.carYear} ${user.carType} (${user.carPlate})`
        : `${user.carYear} ${user.carType}`,
      name: `${user.firstName} ${user.lastName}`,
      rolesDisplay: (
        <div>
          <button className="as-text" type="button" onClick={() => handleRoleClick(user)}><span className="fa-solid fa-pencil" /></button>
          {` ${user.roles.join(', ')}`}
        </div>),
    })),
  };
  return (
    <div className="queue-table">
      <MDBDatatable
        search
        data={advancedData}
        color="dark"
        dark
        entries={200}
      />
      <Modal show={modalUser !== undefined} onHide={() => setModalUser(undefined)} className="modal-roles">
        <Modal.Header closeButton>
          {modalUser?.firstName}
          {' '}
          {modalUser?.lastName}
        </Modal.Header>
        <Modal.Body>
          <UserRoles
            user={modalUser}
            refetch={callUsersGetAll}
            setUser={setModalUser}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UsersTable;
