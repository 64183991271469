import moment from 'moment';

export default function stayDurationOptions(
  time: number,
  currentValue: number,
  count = 96,
  interval = 15,
) {
  let options = [];
  options.push({
    value: currentValue,
    display: moment.unix(currentValue).format('hh:mma'),
  });
  for (let i = 1; i < count; i += 1) {
    const timeInMoment = moment.unix(time);
    const timeRounded = moment(timeInMoment.format('YYYY-MM-DD HH:00'));
    const duration = timeRounded.add(interval * i, 'minutes');
    if (duration.unix() > time && duration.unix() !== currentValue) {
      options.push({
        value: duration.unix(),
        display: `${duration.format('hh:mma')}`,
      });
    }
  }
  options = options.map((row) => {
    const newRow = { ...row };
    let displayMod = '';
    if (moment().add(1, 'day').format('MM-DD') === moment.unix(row.value).format('MM-DD')) displayMod = ' (tomorrow)';
    if (moment().add(2, 'day').format('MM-DD') === moment.unix(row.value).format('MM-DD')) displayMod = ' (+2 day)';
    newRow.display = `${row.display}${displayMod}`;
    return newRow;
  });
  options.sort((a, b) => {
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
    return 0;
  });
  return options;
}
