import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { QUEUE_FIELD_LABEL } from '../../../Config/constant';
import { RootState } from '../../Infrastructure/Redux/store';
import { EnumSettingName } from '../../Settings/Settings.enum';
import { ModelSettings } from '../../Settings/Settings.model';
import { settingsGetAll } from '../../Settings/Settings.services';
import { ModelZoneInfo } from '../../ZoneInfo/ZoneInfo.model';
import { zoneInfoGetAll } from '../../ZoneInfo/ZoneInfo.services';
import { initQueue, ModelQueue } from '../Queue.model';
import stayDurationOptions from '../Utils/stayDurationOptions';
import settingsOptions from '../Utils/settingsOptions';
import townOptions from '../Utils/townOptions';
import { queueCreate, queueUpdate } from '../Queue.services';
import { usersGetAllDriver, usersUpdateProfile } from '../../Users/User.services';
import { actionUpdateUser } from '../../Infrastructure/Redux/userSlice';
import { ModelUser } from '../../Users/Users.model';

function QueueForm({
  activeQueues,
  queue,
  setZoneName,
  refetch,
  adminMode,
  editMode,
}: {
  queue?: ModelQueue | null,
  activeQueues?: ModelQueue[],
  setZoneName?: (zoneName: string) => void,
  refetch: Function,
  adminMode?: boolean,
  editMode?: boolean,
}) {
  const [drivers, setDrivers] = useState<ModelUser[]>([]);
  const [selectDriverId, setSelectDriverId] = useState('');
  const [zoneInfos, setZoneInfos] = useState<ModelZoneInfo[]>([]);
  const [settings, setSettings] = useState<ModelSettings[]>([]);
  const [form, setForm] = useState<ModelQueue>(initQueue);

  const { user, auth } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  // load lookup fields
  useEffect(() => {
    (async () => {
      if (auth.accessToken !== false) {
        const resultSettings = await settingsGetAll(auth.accessToken);
        setSettings(resultSettings);

        const resultZoneInfo = await zoneInfoGetAll(auth.accessToken);
        setZoneInfos(resultZoneInfo);

        if (adminMode && !editMode) {
          const resultDrivers = await usersGetAllDriver(auth.accessToken);
          setDrivers(resultDrivers);
        }
      }
    })();
  }, [auth.accessToken]);

  useEffect(() => {
    let newForm = { ...form };

    if (editMode) {
      // load from parant
      if (queue) newForm = { ...queue };
    } else if (!adminMode) {
      // load profile fields
      newForm.firstName = user.firstName;
      newForm.lastName = user.lastName;
      newForm.phone = user.phone;
      newForm.email = user.email;
      newForm.carYear = user.carYear;
      newForm.carType = user.carType;
      newForm.carPlate = user.carPlate;
    }
    setForm(newForm);
  }, [user, queue]);

  const handleInputChange = (value: string | number, field: string) => {
    const newForm = { ...form };
    newForm[field] = value;
    if (field === 'zoneName') newForm.town = '';
    if (setZoneName) setZoneName(newForm.zoneName);
    newForm.stayDuration = form.leaveTime - form.arrivalTime;
    setForm(newForm);
  };
  const validate = () => {
    const errors: string[] = [];
    const required = [
      'zoneName',
      'town',
      'carYear',
      'carType',
      'carPlate',
    ];
    required.forEach((field) => {
      if (form[field] === undefined || form[field] === '') errors.push(`${QUEUE_FIELD_LABEL[field]} is required`);
    });
    if (form.leaveTime < form.arrivalTime) errors.push('Departure must be later than arrival');
    if (form.leaveTime < moment().unix()) errors.push('Departure must be in the future');
    return errors;
  };
  const handleSave = async () => {
    const errors = validate();
    if (errors.length !== 0) {
      window.alert(errors.join('\n'));
    } else if (auth.accessToken !== false) {
      if (editMode) {
        await queueUpdate(auth.accessToken, form);
      } else {
        await queueCreate(auth.accessToken, form);
      }

      if (!adminMode) {
        // save car info to user
        const newUser = { ...user };
        newUser.carPlate = form.carPlate;
        newUser.carType = form.carType;
        newUser.carYear = form.carYear;
        await usersUpdateProfile(auth.accessToken, newUser);
        dispatch(actionUpdateUser(newUser));
      }

      // update queue
      refetch();
    }
  };
  const handleDriverChange = (driverId: string) => {
    setSelectDriverId(driverId);
    const newForm = initQueue;
    drivers.forEach((driverRow) => {
      if (driverRow._id === driverId) {
        newForm.firstName = driverRow.firstName;
        newForm.lastName = driverRow.lastName;
        newForm.phone = driverRow.phone;
        newForm.email = driverRow.email;
        newForm.carYear = driverRow.carYear;
        newForm.carType = driverRow.carType;
        newForm.carPlate = driverRow.carPlate;
        newForm.sub = driverRow.sub;
      }
    });
    setForm(newForm);
  };
  const nonActiveDrivers: ModelUser[] = [];
  const activeSubs = activeQueues?.map((queueRow) => queueRow.sub) || [];
  drivers.forEach((driverRow) => {
    if (activeSubs.indexOf(driverRow.sub) === -1) nonActiveDrivers.push(driverRow);
  });
  return (
    <div>
      <form>
        {adminMode && !editMode ? (
          <>
            <label>Driver</label>
            <select value={selectDriverId} onChange={(e) => handleDriverChange(e.target.value)}>
              <option value="">-</option>
              {nonActiveDrivers.map(((driverRow) => (
                <option key={driverRow._id} value={driverRow._id}>
                  {driverRow.firstName}
                  {' '}
                  {driverRow.lastName}
                </option>
              )))}
            </select>
          </>
        ) : null}

        <Row>
          <Col md={6}>
            <label>{QUEUE_FIELD_LABEL.zoneName}</label>
            <select
              onChange={(e) => handleInputChange(e.currentTarget.value, 'zoneName')}
              value={form.zoneName}
            >
              <option value="">-</option>
              {zoneInfos.map((q) => (
                <option key={q.zoneName} value={q.zoneName}>
                  {q.zoneName}
                </option>
              ))}
            </select>
          </Col>
          <Col md={6}>
            <label>{QUEUE_FIELD_LABEL.town}</label>
            <select
              value={form.town}
              onChange={(e) => handleInputChange(e.currentTarget.value, 'town')}
              disabled={form.zoneName === ''}
            >
              {townOptions(form.zoneName, zoneInfos)}
            </select>
          </Col>
          <Col md={6}>
            <label>{QUEUE_FIELD_LABEL.arrivalTime}</label>
            <select
              value={form.arrivalTime}
              onChange={(e) => handleInputChange(parseInt(e.currentTarget.value, 10), 'arrivalTime')}
            >
              <option value={moment().unix()}>-</option>
              {stayDurationOptions(moment().unix(), form.arrivalTime).map((row) => (
                <option key={row.value} value={row.value}>{row.display}</option>
              ))}
            </select>
          </Col>
          <Col md={6}>
            <label>{QUEUE_FIELD_LABEL.leaveTime}</label>
            <select
              value={form.leaveTime}
              onChange={(e) => handleInputChange(parseInt(e.currentTarget.value, 10), 'leaveTime')}
            >
              <option value={moment().unix()}>-</option>
              {stayDurationOptions(form.arrivalTime, form.leaveTime).map((row) => (
                <option key={row.value} value={row.value}>{row.display}</option>
              ))}
            </select>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label>{QUEUE_FIELD_LABEL.carYear}</label>
            <select
              value={form.carYear}
              onChange={(e) => handleInputChange(e.currentTarget.value, 'carYear')}
            >
              <option value="">-</option>
              {settingsOptions(EnumSettingName.CarYear, settings).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </Col>
          <Col md={4}>
            <label>{QUEUE_FIELD_LABEL.carType}</label>
            <select
              value={form.carType}
              onChange={(e) => handleInputChange(e.currentTarget.value, 'carType')}
            >
              <option value="">-</option>
              {settingsOptions(EnumSettingName.CarType, settings).map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </Col>
          <Col md={4}>
            <label>{QUEUE_FIELD_LABEL.carPlate}</label>
            <input
              type="text"
              value={form.carPlate}
              onChange={(e) => handleInputChange(e.currentTarget.value, 'carPlate')}
            />
          </Col>
        </Row>
        <label>{QUEUE_FIELD_LABEL.note}</label>
        <textarea
          value={form.note}
          onChange={(e) => handleInputChange(e.currentTarget.value, 'note')}
        />
        <Button variant="primary" onClick={handleSave}>Submit</Button>
      </form>
    </div>
  );
}
QueueForm.defaultProps = {
  queue: null,
  setZoneName: () => {},
  adminMode: false,
  editMode: false,
  activeQueues: [],
};

export default QueueForm;
