export interface ModelUser {
  [key: string]: string | EnumRoles[] | undefined,
  _id?: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  carType: string,
  carPlate: string,
  carYear: string,
  roles: EnumRoles[],
  sub: string
}

export enum EnumRoles {
  Driver = 'Driver',
  Affiliate = 'Affiliate',
  Dispatch = 'Dispatch',
  Admin = 'Admin',
}

export const modelUserInit: ModelUser = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  carType: '',
  carPlate: '',
  carYear: '',
  roles: [],
  sub: '',
};
