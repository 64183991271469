import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ensureRole from '../Domains/Infrastructure/Auth/ensureRole';
import Login from '../Domains/Infrastructure/Auth/Components/Login';
import { RootState } from '../Domains/Infrastructure/Redux/store';
import { EnumRoles } from '../Domains/Users/Users.model';

function MainNav() {
  const { user } = useSelector((state: RootState) => state);
  const {
    isAuthenticated,
  } = useAuth0();

  return (
    <Nav className="flex-column">
      {ensureRole([EnumRoles.Admin, EnumRoles.Driver], user.roles) ? (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/dashboard/queue">
            <span className="fa-solid fa-car" />
            Queue
          </Nav.Link>
        </Nav.Item>
      ) : null}
      {ensureRole([EnumRoles.Admin, EnumRoles.Driver, EnumRoles.Affiliate], user.roles) ? (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/dashboard/zones">
            <span className="fa-solid fa-map" />
            View Zone
          </Nav.Link>
        </Nav.Item>
      ) : null}
      <hr />
      {ensureRole([EnumRoles.Admin, EnumRoles.Dispatch], user.roles) ? (
        <>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/dashboard/dispatch">
              <span className="fa-solid fa-van-shuttle" />
              Dispatch
            </Nav.Link>
          </Nav.Item>
          <hr />
        </>
      ) : null}
      {ensureRole([EnumRoles.Admin], user.roles) ? (
        <>
          <Nav.Item>
            <Nav.Link>
              <span className="fa-solid fa-cog" />
              Settings
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/dashboard/settings/zones" className="level2">
              <span className="fa-solid fa-map-location" />
              Zones
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/dashboard/settings/car" className="level2">
              <span className="fa-solid fa-car-side" />
              Car Info
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/dashboard/settings/announcement" className="level2">
              <span className="fa-solid fa-message" />
              Annoucements
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/dashboard/settings/users" className="level2">
              <span className="fa-solid fa-people-group" />
              User
            </Nav.Link>
          </Nav.Item>
          <hr />
        </>
      ) : null}
      {isAuthenticated ? (
        <Nav.Item>
          <Nav.Link as={NavLink} to="/dashboard/profile">
            <span className="fa-solid fa-user" />
            Profile
          </Nav.Link>
        </Nav.Item>
      ) : null}
      <Nav.Item>
        <Nav.Link>
          <span className="fa-solid fa-lock" />
          <Login />
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export default MainNav;
