import axios from 'axios';
import { errorHandler } from '../Infrastructure/errorHandler';
import { EnumRoles, ModelUser } from './Users.model';

export const usersWhoAmI = async (accessToken: string) => {
  const results = await axios.get<{ user: ModelUser }>(
    `${process.env.REACT_APP_SLS_SERVER}/users/whoami`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.user;
};

export const usersGetAll = async (accessToken: string) => {
  const results = await axios.get<{ users: ModelUser[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/users`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.users;
};

export const usersGetAllDriver = async (accessToken: string) => {
  const results = await axios.get<{ users: ModelUser[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/users/drivers`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.users || [];
};

export const usersUpdateProfile = async (accessToken: string, user: ModelUser) => {
  const results = await axios.post<{ user: ModelUser }>(
    `${process.env.REACT_APP_SLS_SERVER}/users/update-profile`,
    { ...user },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.user;
};

export const usersUpdateRoles = async (
  accessToken: string,
  id: string | undefined,
  roles: EnumRoles[],
) => {
  const results = await axios.post<{ user: ModelUser }>(
    `${process.env.REACT_APP_SLS_SERVER}/users/update-roles`,
    {
      _id: id,
      roles,
    },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.user;
};
