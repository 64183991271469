import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../Infrastructure/Redux/store';
import { ModelZoneInfo } from './ZoneInfo.model';
import { zoneInfoDelete, zoneInfoGetAll, zoneInfoUpdate } from './ZoneInfo.services';

function ZoneInfoForm() {
  const [zoneInfos, setZoneInfos] = useState<ModelZoneInfo[]>([]);
  const { auth } = useSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      if (auth.accessToken !== false) {
        const resultZoneInfo = await zoneInfoGetAll(auth.accessToken);
        if (resultZoneInfo !== undefined) {
          setZoneInfos(resultZoneInfo);
        }
      }
    })();
  }, [auth.accessToken]);
  const handleSave = async (zoneInfo: ModelZoneInfo) => {
    if (auth.accessToken !== false) {
      zoneInfoUpdate(auth.accessToken, zoneInfo);
    }
  };
  const handleAddZoneInfo = async () => {
    if (auth.accessToken !== false) {
      const results = await zoneInfoUpdate(auth.accessToken, {
        zoneDescription: '',
        zoneName: '',
        towns: '',
        sort: 0,
      });
      if (results !== undefined) {
        const newZoneInfo = [...zoneInfos];
        newZoneInfo.push(results.zoneInfo);
        setZoneInfos(newZoneInfo);
      }
    }
  };
  const handleDelete = async (id: string | undefined) => {
    if (auth.accessToken !== false) {
      const results = await zoneInfoDelete(auth.accessToken, id as string);
      if (results?.zoneInfo._id !== undefined) {
        const newZoneInfos: ModelZoneInfo[] = [];
        zoneInfos.forEach((zoneInfoRow) => {
          if (zoneInfoRow._id !== results.zoneInfo._id) newZoneInfos.push(zoneInfoRow);
        });
        setZoneInfos(newZoneInfos);
      }
    }
  };
  const handleInputChange = (
    value: string | number,
    field: string,
    id: string | undefined,
  ) => {
    const newForm = zoneInfos.map((row) => {
      const newRow = { ...row };
      if (row._id === id) {
        newRow[field] = value;
      }
      return newRow;
    });
    setZoneInfos(newForm);
  };
  return (
    <div className="queue-info-form">
      <div className="heading-with-action">
        <h1>Zones</h1>
        <button type="button" className="as-text" onClick={handleAddZoneInfo}>
          <span className="fa-solid fa-plus" />
          {' '}
          Zone
        </button>
      </div>
      {zoneInfos.map((zoneInfoRow) => (
        <div className="queue-row" key={zoneInfoRow._id}>
          <div className="heading-with-action">
            <h2>{zoneInfoRow.zoneName === '' ? '[Blank]' : zoneInfoRow.zoneName}</h2>
            <button
              type="button"
              className="as-text"
              onClick={() => handleDelete(zoneInfoRow._id)}
            >
              <span className="fa-solid fa-trash" />
              {' '}
              Zone
            </button>
          </div>
          <Row>
            <Col lg={8}>
              <label>Zone Name</label>
              <input
                value={zoneInfoRow.zoneName}
                type="text"
                onChange={(e) => handleInputChange(
                  e.target.value,
                  'zoneName',
                  zoneInfoRow._id,
                )}
              />
            </Col>
            <Col>
              <label>Sort</label>
              <input
                value={zoneInfoRow.sort}
                type="number"
                onChange={(e) => handleInputChange(
                  parseInt(e.target.value, 10),
                  'sort',
                  zoneInfoRow._id,
                )}
              />
            </Col>
          </Row>
          <label>Zone Description</label>
          <input
            value={zoneInfoRow.zoneDescription}
            type="text"
            onChange={(e) => handleInputChange(
              e.target.value,
              'zoneDescription',
              zoneInfoRow._id,
            )}
          />
          <label>Towns</label>
          <textarea
            value={zoneInfoRow.towns}
            onChange={(e) => handleInputChange(
              e.target.value,
              'towns',
              zoneInfoRow._id,
            )}
          />
          <Button variant="primary" onClick={() => handleSave(zoneInfoRow)}>Save</Button>
        </div>
      ))}
    </div>
  );
}

export default ZoneInfoForm;
