import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-bootstrap';
import { SITE_NAME } from '../Config/constant';
import UserProfile from '../Domains/Users/UserProfile';

function Profile() {
  return (
    <div className="profile">
      <Helmet>
        <title>{`Profile - ${SITE_NAME}`}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col lg={8}>
          <UserProfile />
        </Col>
      </Row>
    </div>
  );
}

export default Profile;
