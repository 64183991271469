import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../Infrastructure/Redux/store';
import { ModelQueue } from '../Queue.model';
import { queueDelete } from '../Queue.services';

export default function QueueInQueue({
  queue,
  refetch,
}: {
  queue: ModelQueue,
  refetch: Function,
}) {
  const { auth } = useSelector((state: RootState) => state);

  const handleDelete = async () => {
    if (auth.accessToken) {
      if (queue) {
        await queueDelete(auth.accessToken, queue);
        refetch();
      }
    }
  };

  return (
    <div className="in-queue">
      <p>You are currently in a Queue</p>
      <Row>
        <Col>
          <label>Zone</label>
          <div className="value">
            {queue.zoneName}
          </div>
        </Col>
        <Col>
          <label>Town</label>
          <div className="value">
            {queue.town}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Arrival Time</label>
          <div className="value">
            {moment().unix() > queue.arrivalTime
              ? 'Arrived'
              : `Enroute (${moment.unix(queue.arrivalTime).format('hh:mma')})`}
          </div>
        </Col>
        <Col>
          <label>Departure</label>
          <div className="value">
            {moment.unix(queue.leaveTime).fromNow()}
            {` (${moment.unix(queue.leaveTime).format('hh:mma')})`}
          </div>
        </Col>
      </Row>
      <Button onClick={handleDelete}>Remove Yourself from Queue</Button>
    </div>
  );
}
