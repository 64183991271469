import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modelUserInit } from '../../../Users/Users.model';
import { actionUpdateUser } from '../../Redux/userSlice';
import { actionUpdateAccessToken } from '../../Redux/authSlice';
import profileIsEmpty from '../profileIsEmpty';
import { errorHandler } from '../../errorHandler';
import { usersWhoAmI } from '../../../Users/User.services';

function Login() {
  const {
    loginWithRedirect,
    logout,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently().catch(errorHandler);
      if (isAuthenticated && accessToken) {
        dispatch(actionUpdateAccessToken({ accessToken }));
        const user = await usersWhoAmI(accessToken).catch(errorHandler);
        if (user !== undefined) {
          dispatch(actionUpdateUser(user));
          if (profileIsEmpty(user)) {
            navigate('/dashboard/profile');
          } else if (user.roles.length === 0) {
            navigate('/dashboard');
          }
        }
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  const handleAuthClick = () => {
    if (isAuthenticated) {
      dispatch(actionUpdateAccessToken({ accessToken: false }));
      dispatch(actionUpdateUser(modelUserInit));
      logout({ returnTo: window.location.origin });
    } else {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  };
  if (isLoading) {
    return (<div>Loading...</div>);
  }
  return (
    <button
      type="button"
      className="as-text"
      onClick={() => handleAuthClick()}
    >
      {isAuthenticated ? 'Log out' : 'Log In'}
    </button>
  );
}

export default Login;
