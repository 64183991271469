import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../../Config/constant';
import ZoneInfoForm from '../../Domains/ZoneInfo/ZoneInfoForm';

function SettingsQueue() {
  return (
    <div className="settings-queue-info">
      <Helmet>
        <title>{`Zone Info Settings - ${SITE_NAME}`}</title>
      </Helmet>
      <Row className="justify-content-center">
        <Col md={8}>
          <ZoneInfoForm />
        </Col>
      </Row>
    </div>
  );
}

export default SettingsQueue;
