import axios from 'axios';
import { errorHandler } from '../Infrastructure/errorHandler';
import { ModelQueue } from './Queue.model';

export const queueGetAll = async (accessToken: string) => {
  const results = await axios.get<{ queues: ModelQueue[] }>(
    `${process.env.REACT_APP_SLS_SERVER}/queue`,
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.queues;
};

export const queueCreate = async (accessToken: string, queue: ModelQueue) => {
  const results = await axios.post<{ queue: ModelQueue }>(
    `${process.env.REACT_APP_SLS_SERVER}/queue/create`,
    { ...queue },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.queue;
};

export const queueUpdate = async (accessToken: string, queue: ModelQueue) => {
  const results = await axios.post<{ queue: ModelQueue }>(
    `${process.env.REACT_APP_SLS_SERVER}/queue/update`,
    { ...queue },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.queue;
};

export const queueDelete = async (accessToken: string, queue: ModelQueue) => {
  const results = await axios.post<{ queue: ModelQueue }>(
    `${process.env.REACT_APP_SLS_SERVER}/queue/delete`,
    { ...queue },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  ).catch(errorHandler);
  return results?.data.queue;
};
