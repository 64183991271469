import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { SITE_NAME } from '../Config/constant';
import { RootState } from '../Domains/Infrastructure/Redux/store';

export default function Dashboard() {
  const { user } = useSelector((state: RootState) => state);
  const {
    isAuthenticated,
  } = useAuth0();
  return (
    <div className="dashboard">
      <Helmet>
        <title>{`Dashboard - ${SITE_NAME}`}</title>
      </Helmet>
      {isAuthenticated && user.roles.length === 0 ? (
        <div>
          <h1>Welcome NVLUX</h1>
          <p>
            Your registerion is being reviewed. Please contact NVLUX regarding
            the status of your account.
          </p>
        </div>
      ) : null}
      {!isAuthenticated ? (
        <p>Please login</p>
      ) : null}
    </div>
  );
}
