import moment from 'moment';

export enum EnumQueueStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface ModelQueue {
  [key: string]: string | number,
  timestamp: number,
  zoneName: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  town: string,
  arrivalTime: number,
  stayDuration: number,
  leaveTime: number,
  carType: string,
  carPlate: string,
  carYear: string,
  note: string,
  status: EnumQueueStatus,
  sub: string,
}

export const initQueue: ModelQueue = {
  timestamp: moment().unix(),
  zoneName: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  town: '',
  arrivalTime: moment().unix(),
  stayDuration: 0,
  leaveTime: moment().unix(),
  carType: '',
  carPlate: '',
  carYear: '',
  note: '',
  sub: '',
  status: EnumQueueStatus.Active,
};
