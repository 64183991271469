import { parsePhoneNumber } from 'awesome-phonenumber';
import { MDBDatatable } from 'mdb-react-ui-kit';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Modal, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../Infrastructure/Redux/store';
import { EnumRoles } from '../../Users/Users.model';
import { ModelQueue } from '../Queue.model';
import { queueDelete } from '../Queue.services';
import QueueForm from './QueueForm';

function QueueTable({
  role,
  queues,
  refetch,
}: {
  role: string,
  queues: ModelQueue[],
  refetch: Function,
}) {
  let columns = [
    { label: 'Submitted On', field: 'timestampDisplay' },
    { label: 'Town', field: 'town' },
    { label: 'Car Type', field: 'carTypeDisplay' },
    { label: 'Arrival', field: 'arrivalStatus' },
    { label: 'Departure', field: 'stayDurationDisplay' },
  ];
  queues.sort((a, b) => {
    const now = moment().unix();
    let aValue = a.arrivalTime;
    let bValue = b.arrivalTime;
    if (a.arrivalTime > now) aValue = now;
    if (b.arrivalTime > now) bValue = now;
    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;
    return 0;
  });
  switch (role) {
    case EnumRoles.Admin:
      columns = [
        { label: 'Actions', field: 'actions' },
        { label: 'Town', field: 'town' },
        { label: 'Name', field: 'name' },
        { label: 'Phone', field: 'phone' },
        { label: 'Car Type', field: 'carTypeDisplay' },
        { label: 'Plate', field: 'carPlate' },
        { label: 'Arrival', field: 'arrivalStatus' },
        { label: 'Departure', field: 'stayDurationDisplay' },
      ];
      break;
    case EnumRoles.Affiliate:
      columns = [
        { label: 'Town', field: 'town' },
        { label: 'Car Type', field: 'carTypeDisplay' },
        { label: 'Arrival', field: 'arrivalStatus' },
        { label: 'Departure', field: 'stayDurationDisplay' },
      ];
      break;
    default:
  }
  const [selectedRow, setSelectedRow] = useState<null | ModelQueue>(null);
  const [showEdit, setShowEdit] = useState(false);

  const { auth } = useSelector((state: RootState) => state);

  const handleDeleteClick = async (row: ModelQueue) => {
    setSelectedRow(row);
    if (window.confirm('Are you sure you want to delete?') === true) {
      if (auth.accessToken) {
        await queueDelete(auth.accessToken, row);
        refetch();
      }
    }
    // setShowConfirm(true);
  };
  const handleEditClick = (row: ModelQueue) => {
    setSelectedRow(row);
    setShowEdit(true);
  };
  const advancedData = {
    columns,
    rows: queues.map((row) => ({
      ...row,
      phone: (
        <a href={parsePhoneNumber(row.phone, 'US').getNumber('rfc3966')}>
          {parsePhoneNumber(row.phone, 'US').getNumber('national')}
        </a>
      ),
      name: `${row.firstName} ${row.lastName}`,
      arrivalStatus: row.arrivalTime < moment().unix() ? (<span className="arrived">Arrived</span>) : `En Route (${moment.unix(row.arrivalTime).format('hh:mma')})`,
      arrivalTimeDisplay: moment.unix(row.arrivalTime).format('hh:mma'),
      carTypeDisplay: `${row.carYear}, ${row.carType}`,
      stayDurationDisplay: `${moment.unix(row.leaveTime).fromNow()} (${moment.unix(row.leaveTime).format('hh:mma')})`,
      timestampDisplay: moment.unix(row.timestamp).format('MM-DD hh:mma'),
      actions: (
        <div>
          <OverlayTrigger
            overlay={(
              <Tooltip>
                {row.note.split('\n').map((noteRow, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={i}>
                    {noteRow}
                    <br />
                  </React.Fragment>
                ))}
              </Tooltip>
          )}
          >
            <button type="button" className="action as-text"><span className="fa-solid fa-align-left" /></button>
          </OverlayTrigger>
          <button type="button" className="action as-text" onClick={() => handleEditClick(row)}><span className="fa-solid fa-pencil" /></button>
          <button type="button" className="action as-text" onClick={() => handleDeleteClick(row)}><span className="fa-solid fa-trash" /></button>
        </div>
      ),
    })),
  };
  return (
    <div className="queue-table">
      <MDBDatatable
        search
        data={advancedData}
        color="dark"
        dark
        entries={200}
      />
      <Modal show={showEdit} onHide={() => setShowEdit(false)} size="lg">
        <Modal.Header closeButton>
          Edit
        </Modal.Header>
        <Modal.Body>
          <QueueForm
            queue={selectedRow}
            adminMode
            editMode
            refetch={() => {
              setSelectedRow(null);
              setShowEdit(false);
              refetch();
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default QueueTable;
