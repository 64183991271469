import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { initQueue, ModelQueue } from '../Queue.model';
import QueueForm from './QueueForm';
import QueueTable from './QueueTable';
import { filterQueueByZone } from '../Utils/filterQueueByZone';
import { EnumRoles } from '../../Users/Users.model';
import { queueGetAll } from '../Queue.services';
import { RootState } from '../../Infrastructure/Redux/store';

function QueueByZone({ zoneName, role }: { zoneName: string, role: string }) {
  const [queues, setQueues] = useState<ModelQueue[]>([]);
  const [showForm, setShowForm] = useState(false);
  const { auth } = useSelector((state: RootState) => state);

  const callQueueGetAll = async () => {
    if (auth.accessToken !== false) {
      const resultQueue = await queueGetAll(auth.accessToken);
      if (resultQueue !== undefined) {
        setQueues(resultQueue);
      }
    }
  };
  useEffect(() => {
    callQueueGetAll();
  }, [auth.accessToken]);
  return (
    <div className="queue-by-name">
      {zoneName !== '' ? (
        <div className="queue-group">
          <div className="heading-with-action">
            <h2>
              <div className="queue-count">
                <span className="fa-solid fa-car" />
                <div className="count">{filterQueueByZone(queues, zoneName).length}</div>
              </div>
              {zoneName}
            </h2>
            {role === EnumRoles.Admin ? (
              <button type="button" className="actions as-text" onClick={() => setShowForm(true)}>
                <span className="fa-solid fa-plus" />
                {' '}
                Add
              </button>
            ) : null}
            <Modal size="lg" show={showForm} onHide={() => setShowForm(false)}>
              <Modal.Header closeButton>Add to Queue</Modal.Header>
              <Modal.Body>
                <QueueForm
                  queue={{
                    ...initQueue,
                    zoneName,
                  }}
                  activeQueues={queues}
                  adminMode
                  refetch={() => {
                    setShowForm(false);
                    callQueueGetAll();
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
          <QueueTable
            queues={filterQueueByZone(queues, zoneName)}
            role={role}
            refetch={callQueueGetAll}
          />
        </div>

      ) : null}
    </div>
  );
}

export default QueueByZone;
