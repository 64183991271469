import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useAuth0 } from '@auth0/auth0-react';
import { LABEL_ZONE, SITE_NAME } from '../Config/constant';
import { EnumRoles } from '../Domains/Users/Users.model';
import { zoneInfoByName } from '../Domains/ZoneInfo/Utils/zoneInfoByName';
import QueueByZone from '../Domains/Queue/Components/QueueByZone';
import { EnumSettingName } from '../Domains/Settings/Settings.enum';
import { ModelZoneInfo } from '../Domains/ZoneInfo/ZoneInfo.model';
import { zoneInfoGetAll } from '../Domains/ZoneInfo/ZoneInfo.services';
import { ModelSettings } from '../Domains/Settings/Settings.model';
import { settingsGetAll } from '../Domains/Settings/Settings.services';

function Client() {
  const [zoneInfos, setZoneInfos] = useState<ModelZoneInfo[]>([]);
  const [settings, setSettings] = useState<ModelSettings[]>([]);
  const {
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      const resultZoneInfo = await zoneInfoGetAll(accessToken);
      if (resultZoneInfo !== undefined) {
        setZoneInfos(resultZoneInfo);
      }
      const resultSettings = await settingsGetAll(accessToken);
      if (resultSettings !== undefined) {
        setSettings(resultSettings);
      }
    })();
  }, [getAccessTokenSilently]);
  const [currentZoneName, setCurrentZoneName] = useState('');

  let announcement = '';
  settings.forEach((row) => {
    if (row.settingName === EnumSettingName.Announcement) announcement = row.value;
  });

  return (
    <div className="clients">
      <Helmet>
        <title>{`View Zones - ${SITE_NAME}`}</title>
      </Helmet>
      <Alert>{announcement}</Alert>
      <Row className="justify-content-center">
        <Col md={6}>
          <label>{LABEL_ZONE}</label>
          <select
            value={currentZoneName}
            onChange={(e) => { setCurrentZoneName(e.target.value); }}
          >
            <option value="">-</option>
            {zoneInfos.map((q) => (
              <option key={q.zoneName} value={q.zoneName}>
                {q.zoneName}
              </option>
            ))}
          </select>
          {zoneInfoByName(zoneInfos, currentZoneName)
            ? (
              <div className="zone-description">
                {zoneInfoByName(zoneInfos, currentZoneName)?.zoneDescription}
              </div>
            ) : null}
        </Col>
      </Row>
      <QueueByZone zoneName={currentZoneName} role={EnumRoles.Affiliate} />
    </div>
  );
}

export default Client;
