import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { usersUpdateRoles } from './User.services';
import { EnumRoles, ModelUser } from './Users.model';

function UserRoles({
  user,
  refetch,
  setUser,
}: {
  user: ModelUser | undefined
  refetch: Function,
  setUser: Function,
}) {
  const {
    getAccessTokenSilently,
  } = useAuth0();
  if (user === undefined) return null;

  const [roles, setRoles] = useState<EnumRoles[]>([]);
  useEffect(() => {
    setRoles(user.roles);
  }, []);
  const handleChange = (role: EnumRoles) => {
    const newRoles: EnumRoles[] = [...roles];
    if (newRoles.indexOf(role) === -1) {
      newRoles.push(role);
    } else {
      newRoles.splice(newRoles.indexOf(role), 1);
    }
    setRoles(newRoles);
  };
  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently();
    await usersUpdateRoles(accessToken, user._id, roles);
    refetch();
    setUser(undefined);
  };
  return (
    <div className="user-roles">
      <div className="user-role">
        <input
          type="checkbox"
          checked={roles.indexOf(EnumRoles.Admin) !== -1}
          onChange={() => handleChange(EnumRoles.Admin)}
        />
        <label>{EnumRoles.Admin}</label>
      </div>
      <div className="user-role">
        <input
          type="checkbox"
          checked={roles.indexOf(EnumRoles.Affiliate) !== -1}
          onChange={() => handleChange(EnumRoles.Affiliate)}
        />
        <label>{EnumRoles.Affiliate}</label>
      </div>
      <div className="user-role">
        <input
          type="checkbox"
          checked={roles.indexOf(EnumRoles.Driver) !== -1}
          onChange={() => handleChange(EnumRoles.Driver)}
        />
        <label>{EnumRoles.Driver}</label>
      </div>
      <div className="user-role">
        <input
          type="checkbox"
          checked={roles.indexOf(EnumRoles.Dispatch) !== -1}
          onChange={() => handleChange(EnumRoles.Dispatch)}
        />
        <label>{EnumRoles.Dispatch}</label>
      </div>
      <Button onClick={handleSave}>Save</Button>
    </div>
  );
}

export default UserRoles;
